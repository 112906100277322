export const ALL_CATEGORIES = "A";
export const MISSING_CATEGORY = "S";

export const TYPE = "type";
export const WORD_TYPE = "wordType";
export const SENTENCE_TYPE = "sentenceType";
export const NAME = "name";
export const PRONUNCIATION = "pronunciation";
export const COMMON_LEVEL = "commonLevel";
export const SPELLING_LEVEL = "spellingLevel";
export const GRAMMAR_LEVEL = "grammarLevel";
export const IMAGE_URL = "imageURL";
export const DOWNLOAD_IMAGE_URL = "downloadImageURL";
export const CROPPED_IMAGE_URL = "croppedImageURL";
export const DOWNLOAD_CROPPED_IMAGE_URL = "downloadCroppedImageURL";
export const IMAGE_BACKGROUND_MATCHES = "imageAppBackgroundMatches";
export const CATEGORY_ID = "categoryId";
export const PLURAL = "plural";
export const DEFINITE = "definite";
export const GENDER = "gender";
export const TEXT = "text";
export const TITLE = "title";
export const QUESTIONS = "questions";

export const WORD_TYPE_ADJECTIVE = "adjective";
export const WORD_TYPE_ADVERB= "adverb";
export const WORD_TYPE_INTERJECTION = "interjection";
export const WORD_TYPE_CONJUNCTION = "konjunktion";
export const WORD_TYPE_PREPOSITION = "preposition";
export const WORD_TYPE_PRONOUN = "pronoun";
export const WORD_TYPE_NUMERAL = "räkneord";
export const WORD_TYPE_NOUN = "noun";
export const WORD_TYPE_VERB = "verb";

export const SENTENCE_TYPE_COMPREHENSION = "SENTENCE_TYPE_COMPREHENSION";
export const SENTENCE_TYPE_COMMON_PHRASES = "SENTENCE_TYPE_COMMON_PHRASES";
export const SENTENCE_TYPE_PRONOUNS = "SENTENCE_TYPE_PRONOUNS";
export const SENTENCE_TYPE_PREPOSITIONS = "SENTENCE_TYPE_PREPOSITIONS";
export const SENTENCE_TYPE_PLURAL = "SENTENCE_TYPE_PLURAL";

export const TYPE_WORD = "word";
export const TYPE_TEXT = "typeText";
export const TYPE_SENTENCE = "typeSentence";

export const INPUT_TYPE_TEXT = "INPUT_TYPE_TEXT";
export const INPUT_TYPE_SELECT = "INPUT_TYPE_SELECT";
export const INPUT_TYPE_TEXTAREA = "INPUT_TYPE_TEXTAREA";
export const INPUT_TYPE_IMAGE = "INPUT_TYPE_IMAGE";
export const INPUT_TYPE_CATEGORY = "INPUT_TYPE_CATEGORY";
export const INPUT_TYPE_QUESTIONS = "INPUT_TYPE_QUESTIONS";

export const IMAGE_PREVIEW = "IMAGE_PREVIEW";

const DIFFICULTY = {
    type: INPUT_TYPE_SELECT,
    options: [{
        label: "(1) Lätt",
        value: "1"
    }, {
        label: "(2) Medellätt",
        value: "2"
    }, {
        label: "(3) Medel",
        value: "3"
    }, {
        label: "(4) Medelsvårt",
        value: "4"
    }, {
        label: "(5) Svårt",
        value: "5"
    }]
};

export const inputTypeMapping = {
    [TYPE]: {
        type: INPUT_TYPE_SELECT,
        label: "Typ",
        options: [{
            label: "Ord",
            value: TYPE_WORD
        }, {
            label: "Text",
            value: TYPE_TEXT
        }, {
            label: "Mening",
            value: TYPE_SENTENCE
        }]
    },
    [WORD_TYPE]: {
        type: INPUT_TYPE_SELECT,
        label: "Ordklass",
        options: [{
            label: "Adjektiv",
            value: WORD_TYPE_ADJECTIVE
        }, {
            label: "Adverb",
            value: WORD_TYPE_ADVERB
        }, {
            label: "Interjektion",
            value: WORD_TYPE_INTERJECTION
        }, {
            label: "Konjunktion",
            value: WORD_TYPE_CONJUNCTION
        }, {
            label: "Preposition",
            value: WORD_TYPE_PREPOSITION
        }, {
            label: "Pronomen",
            value: WORD_TYPE_PRONOUN
        }, {
            label: "Räkneord",
            value: WORD_TYPE_NUMERAL
        }, {
            label: "Substantiv",
            value: WORD_TYPE_NOUN
        }, {
            label: "Verb",
            value: WORD_TYPE_VERB
        }]
    },
    [SENTENCE_TYPE]: {
        type: INPUT_TYPE_SELECT,
        label: "Type av mening",
        options: [{
            label: "Meningsförståelse",
            value: SENTENCE_TYPE_COMPREHENSION
        }, {
            label: "Vardagliga fraser",
            value: SENTENCE_TYPE_COMMON_PHRASES
        }, {
            label: "Pronomen",
            value: SENTENCE_TYPE_PRONOUNS
        }, {
            label: "Prepositioner",
            value: SENTENCE_TYPE_PREPOSITIONS
        }, {
            label: "Plural",
            value: SENTENCE_TYPE_PLURAL
        }]
    },
    [NAME]: {
        type: INPUT_TYPE_TEXT,
        label: "Grundform"
    },
    [PRONUNCIATION]: {
        type: INPUT_TYPE_TEXT,
        label: "Uttal (om dåligt i appen)"
    },
    [COMMON_LEVEL]: {
        ...DIFFICULTY,
        label: "Vanligt/ovanligt"
    },
    [SPELLING_LEVEL]: {
        ...DIFFICULTY,
        label: "Stavning & Uttal"
    },
    [GRAMMAR_LEVEL]: {
        ...DIFFICULTY,
        label: "Grammatik"
    },
    [IMAGE_URL]: {
        type: INPUT_TYPE_IMAGE
    },
    [CROPPED_IMAGE_URL]: {
        type: IMAGE_PREVIEW
    },
    [CATEGORY_ID]: {
        type: INPUT_TYPE_CATEGORY,
        label: "Kategori"
    },
    [PLURAL]: {
        type: INPUT_TYPE_TEXT,
        label: "Pluralform"
    },
    [DEFINITE]: {
        type: INPUT_TYPE_TEXT,
        label: "Bestämd form"
    },
    [GENDER]: {
        type: INPUT_TYPE_SELECT,
        label: "Obestämd artikel",
        options: [{
            label: "",
            value: ""
        }, {
            label: "En",
            value: "en"
        }, {
            label: "Ett",
            value: "ett"
        }]
    },
    [TEXT]: {
        type: INPUT_TYPE_TEXTAREA,
        label: "Text"
    },
    [TITLE]: {
        type: INPUT_TYPE_TEXT,
        label: "Titel"
    },
    [QUESTIONS]: {
        type: INPUT_TYPE_QUESTIONS,
        label: "Frågor"
    }
};

export function getEntityFields({type, wordType}) {
    let fields = [TYPE];
    if (type === TYPE_WORD) {
        fields = fields.concat([WORD_TYPE, NAME, PRONUNCIATION, COMMON_LEVEL, SPELLING_LEVEL, IMAGE_URL, CROPPED_IMAGE_URL]);
        if (wordType === WORD_TYPE_NOUN) {
            fields = fields.concat([CATEGORY_ID, PLURAL, DEFINITE, GENDER]);
        }
    } else if (type === TYPE_TEXT) {
        fields = fields.concat([TITLE, TEXT, QUESTIONS]);
    } else if (type === TYPE_SENTENCE) {
        fields = fields.concat([SENTENCE_TYPE, TEXT, GRAMMAR_LEVEL, SPELLING_LEVEL]);
    }
    return fields;
}

export const CATEGORY_FIELDS = ["name", "parentId"];
