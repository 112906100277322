import React from "react";
import classNames from "classnames";

export default function IconButton({icon, onClick, className, color, size = "medium"}) {
    return (
        <button className={classNames("btn-flat", "waves-effect",  "btn-" + size, className)} onClick={onClick}>
            <i className={classNames("material-icons", (color || "teal") + "-text")}>{icon}</i>
        </button>
    );
}
