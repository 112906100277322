import React from "react";
import * as core from "../core";

export default function Header(props) {
    const state = props.state;
    return (
        <div className="teal lighten-4" style={{position: "relative", zIndex: "1", height: "100px", minWidth: "700px", display: "flex", justifyContent: "space-between", boxShadow: "1px 0px 4px 0px #302f2f"}}>
            <div className="pl4 dFlex alignItemsCenter">
                <img src="https://glossa.se/images/logo.png" className="mr4 p2" style={{height: "100%"}}/>
                <span style={{whiteSpace: "noWrap", fontSize: "40px"}}>Glossa Admin</span>
            </div>
            <div className="dFlex alignItemsCenter pr3" style={{height: "100%"}}>
                <div>
                    <div>
                        {core.getUserData(state).email}
                    </div>
                    <button className="waves-teal btn" onClick={function () {
                        props.swapState(core.handleRequest, "signOut");
                    }}>Logga Ut</button>
                </div>
            </div>
        </div>
    );
}
