import React from "react";
import ReactDOM from "react-dom";
import _ from "understreck";
import modelMaker from "./util/mutable/mutableThing"
import AppComponent from "./components/AppComponent";
import handleSideEffects from "./sideEffects";
import * as core from "./core";
import * as services from "./services";
import {getPageDataFromUrl, getUrl, pageIds} from "./paths";

export default function App({ system, initialState, onStateChange = () => {}, initialPage = pageIds.LIST_ENTITIES}) {

    let model = modelMaker(initialState || core.createInitialState());

    function handlePopState() {
        let newPageData = getPageDataFromUrl(system.window.location.pathname);
        model.swap(core.onPageIdChange, newPageData, {keepPageState: true});
    }

    function addGlobalListeners() {
        model.on("change", function (state) {
            onStateChange(state);
            handleSideEffects({state, swapState: model.swap});
            render();
        });
        system.window.addEventListener("popstate", handlePopState);
        system.window.addEventListener("resize", _.debounce(render, 300), true);
        // TODO: Should be handled as a normal service
        services.onAuthStateChangedEvent(({ user, isAdmin }) => model.swap(core.userAuthChanged, user, isAdmin));
    }

    function render() {
        ReactDOM.render(<AppComponent state={model.deref()}
                                      width={system.window.document.documentElement.clientWidth}
                                      swapState={model.swap}
                                      triggerEvent={triggerEvent}
                                      system={system}/>, document.getElementById("root"));
    }

    function triggerEvent(eventName, eventData) {
        var event = {
            "navigate": function () {
                let {url, keepPageState} = eventData;
                system.window.history.pushState({}, null, url);
                let newPageData = getPageDataFromUrl(url);
                model.swap(core.onPageIdChange, newPageData, {keepPageState: keepPageState});
            },
            "redirect": function () {
                let {url, keepPageState} = eventData;
                system.window.history.replaceState({}, null, url);
                let newPageData = getPageDataFromUrl(url);
                model.swap(core.onPageIdChange, newPageData, {keepPageState: keepPageState});
            }
        }[eventName];

        if (event) {
            event();
        }
    }

    function start() {
        addGlobalListeners();
        let pageDataFromUrl = getPageDataFromUrl(system.window.location.pathname);
        if (pageDataFromUrl) {
            model.swap(core.onPageIdChange, pageDataFromUrl, {keepPageState: true});
        } else {
            triggerEvent("redirect", {url: getUrl(initialPage)});
        }
    }

    return {
        start: start,
    }
}
