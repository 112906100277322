import * as core from "../core";
import React from "react";
import Spinner from "../components/Spinner";
import {pageNames, pageIds} from "../paths";
import EntityEditForm from "../components/EntityEditForm";

export default function AddEntity(props) {
    const state = props.state;
    let newEntity = core.getNewEntity(state);
    const isFetching = core.isFetching(state, "addEntity");
    return (
        <div className="px4 width900">
            <h2>{pageNames[pageIds.ADD_ENTITY]}</h2>
            <form id="addEntityForm" onSubmit={function (e) {
                e.preventDefault();
                if (!core.isNewEntityDuplicate(state) || props.system.window.confirm("Ett objekt med namnet " + newEntity.name + " finns redan i databasen. Är du säker på att du vill lägga till ett till?")) {
                    props.swapState(core.handleRequest, "addEntity");
                }
            }}>
                <EntityEditForm {...props}
                                state={state}
                                entity={newEntity}
                                onUpdateEntity={function ({type, value}) {
                                    props.swapState(core.newEntityInputChange, {type: type, value: value});
                                }}
                                disabled={isFetching}/>
                {isFetching ?
                    <Spinner/> :
                    <div className={"mt3"}>
                        <button disabled={isFetching || !core.isValidNewEntity(state)}
                                className="waves-effect waves-teal btn"
                                type={"submit"}>
                            Skapa {core.getEntityTypeName(newEntity)}</button>
                        <button className="ml3 waves-effect grey btn"
                                type="button"
                                onClick={function (e) {
                                    props.swapState(core.resetNewEntityData)
                                    newEntity = core.getNewEntity(state)
                                    document.getElementById('addEntityForm').reset()
                                }}>Återställ</button>
                        {core.shouldShowEntityCreatedFeedback(state) ? <span className="green-text" style={{marginLeft: "2rem"}}>Ord skapat!</span> : null}
                    </div>
                }
            </form>
        </div>
    );
}
