import React from "react";
import classNames from "classnames";
import lodash from "lodash";

export default function Grid({width, breakPoints = [800, 1000, 1350], elements = []}) {
    if (!width) {
        throw new Error("width prop is mandatory for the Grid component. Did you forget the {...props}?")
    }
    let columnCount = 1;
    while (columnCount <= breakPoints.length && width > breakPoints[columnCount - 1]) {
        columnCount += 1;
    }

    function getColSpan(element) {
        return Math.min(columnCount, element.colSpan || 1);
    }
    let rows = [[]];
    lodash.compact(elements).forEach(function (element) {
        let rowSum = rows[rows.length - 1].reduce(function (sum, e) {
            return sum + getColSpan(e);
        }, 0);
        if (rowSum + getColSpan(element) > columnCount) {
            rows.push([]);
        }
        rows[rows.length - 1].push(element);
    });

    return <div>
        {rows.map(function (row, index) {
            return <div key={index} className={classNames("row", "dFlex", "alignItemsCenter")}>
                {row.map(function (cell) {
                    return <div key={cell.id}
                                style={{
                                    display: "inline-block",
                                    width: (getColSpan(cell)*100/columnCount) + "%"
                                }}
                                className={classNames("col", cell.className)}>
                        {cell.element}
                    </div>
                })}
            </div>
        })}
    </div>;
}
