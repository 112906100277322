import React from "react";

export default function TextArea({onChange, value, id, disabled, label, height = 180}) {
    return <div>
        <label htmlFor={id}>
            {label}
        </label>
        <textarea disabled={disabled}
                  style={{height: height + "px"}}
                  id={id}
                  value={value}
                  onChange={function ({target: {value}}) {
                      onChange(value);
                  }}/>
    </div>;
}
