import React from "react";

export default function CheckBox({onChange, value, disabled, label}) {
    return <label>
        <input type="checkbox"
               disabled={disabled}
               className="filled-in"
               checked={value}
               onChange={function () {
                   onChange(!value);
               }}/>
        <span>{label}</span>
    </label>;
}
