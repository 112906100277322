import * as core from "../core";
import React from "react";
import Spinner from "./Spinner";
import SignIn from "../pages/SignIn";
import Header from "./Header";
import SideBar from "./SideBar";
import {getPageComponent} from "../paths";

export default function AppComponent(props) {
    const state = props.state;
    const ref = React.createRef();

    const PageContent = getPageComponent(core.getPageId(state));

    return (
        <div className="teal lighten-5" style={{position: "absolute", width: "100%", height: "100%"}}>
            {core.isFetchingUserAuth(state) ?
                <Spinner /> :
                core.isUserSignedIn(state) ?
                    core.isUserAdmin(state) ?
                        <div className="dFlex flexColumn" style={{height: "100%"}}>
                            <Header {...props}/>
                            <div className="dFlex" style={{overflow: "hidden", height: "100%"}}>
                                <SideBar {...props}/>
                                <div ref={ref} style={{width: "100%", overflow: "auto"}}>
                                    <PageContent {...props} parentRef={ref}/>
                                </div>
                            </div>
                        </div> :
                        <div className="container" style={{height: "100%"}}>
                            <Header {...props}/>
                            <h5 className="center-align section">Du behöver adminrättigheter för att komma åt den här sidan. Ta kontakt med din systemadministratör</h5>
                        </div> :
                    <SignIn {...props} />}
        </div>
    );
}
