const common = {

};

const dev = {
    hotReload: true,
    firebase: {
        apiKey: "AIzaSyCF9-PM6-D9TjIboK2WtaWg6b8dGmN4jL0",
        authDomain: "glossa-test.firebaseapp.com",
        projectId: "glossa-test",
        storageBucket: "glossa-test.appspot.com"
    },
};

const prod = {
    hotReload: false,
    firebase: {
        apiKey: "AIzaSyCtaF3RWq29Q-cJGuKk2oVxJjkaTZ0i9Hc",
        authDomain: "glossa-f3110.firebaseapp.com",
        projectId: "glossa-f3110",
        storageBucket: "glossa-f3110",
    },
};

export default {
    ...common,
    ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};