import * as core from "../core";
import React from "react";
import Spinner from "../components/Spinner";
import {pageNames, pageIds} from "../paths";
import Input from "../components/Input";
import CategorySelect from "../components/CategorySelect";

export default function EditCategories(props) {
    const state = props.state;
    const selectedCategoryId = core.getSelectedCategoryEditorId(state);
    const selectedCategory = selectedCategoryId && core.getCategoryById(state, selectedCategoryId);
    const selectedCategoryWithChanges = selectedCategoryId && core.getCategoryWithChanges(state, selectedCategoryId);
    const leftCategories = core.getCategoriesByParentId(state, selectedCategory && selectedCategory.parentId);
    const rightCategories = selectedCategoryId ? core.getSubCategoriesAsList(state, selectedCategoryId) : [];
    const selectedCategoryChildrenCount = core.getEntitiesByCategoryId(state, selectedCategoryId).length + rightCategories.length;
    return (
        <div className="px4">
            <h2>{pageNames[pageIds.EDIT_CATEGORIES]}</h2>
            <div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <button className="btn-flat "
                            disabled={!selectedCategoryId}
                            onClick={function () {
                                props.swapState(core.categoryEditorCategorySelected, {id: selectedCategory && selectedCategory.parentId});
                            }}>
                        <i className="material-icons">arrow_back</i>
                    </button>
                    <div>{selectedCategory && selectedCategory.parentId && core.getCategoryById(state, selectedCategory.parentId).name}</div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    {[leftCategories, rightCategories].map(function (categories, index) {
                        return <div key={"categories" + index} style={{flexBasis: "100%"}}>
                            {categories.length ? <div className="collection">
                                {categories.map(function (category) {
                                    const isSelected = core.isCategoryEditorCategorySelected(state, category.id);
                                    const subCategoryCount = core.getSubCategoriesAsList(state, category.id).length;
                                    return (
                                        <a key={category.id}
                                           href="#!"
                                           className={"collection-item " + (isSelected ? "active" : "")}
                                           onClick={function () {
                                               props.swapState(core.categoryEditorCategorySelected, {id: category.id});
                                           }}>
                                            {category.name}
                                            {subCategoryCount ? <span className="badge">{subCategoryCount}</span>: null}
                                        </a>);
                                })}
                            </div> : null}
                        </div>;
                    })}
                    <div style={{flexBasis: "100%"}}>
                        {selectedCategoryId &&
                        <div className="card">
                            <div className="card-content">
                                <Input disabled={core.isFetchingUpdateOrRemoveCategory(state, selectedCategoryId)}
                                       label={"Namn"}
                                       value={selectedCategoryWithChanges.name}
                                       onChange={function (value) {
                                           props.swapState(core.categoryEditorChange, {type: "name", value: value, id: selectedCategoryId});
                                       }}/>
                                <CategorySelect disabled={core.isFetchingUpdateOrRemoveCategory(state, selectedCategoryId)}
                                                state={state}
                                                label={"Förälder"}
                                                value={selectedCategoryWithChanges.parentId || ""}
                                                onChange={function (value) {
                                                    props.swapState(core.categoryEditorChange, {type: "parentId", value: value, id: selectedCategoryId});
                                                }}/>
                                {core.isFetchingUpdateOrRemoveCategory(state, selectedCategoryId) ?
                                    <Spinner/> :
                                    <div>
                                        <button style={{marginRight: "5px"}}
                                                className="waves-effect waves-teal btn"
                                                disabled={!core.anyCategoryChanges(state) || !core.isValidUpdatedCategory(state)}
                                                onClick={function () {
                                                    props.swapState(core.handleRequest, "updateCategory", selectedCategoryId);
                                                }}>Save</button>
                                        {core.anyCategoryChanges(state) && <button className="waves-effect grey btn"
                                                                                   onClick={function () {
                                                                                       props.swapState(core.resetCategoryChanges, {categoryId: selectedCategoryId});
                                                                                   }}>Reset Changes</button>}
                                        <button className="btn waves-effect red right" disabled={selectedCategoryChildrenCount > 0}>
                                            <i className="material-icons small"
                                               onClick={function () {
                                                   if (props.system.window.confirm("Är du säker på att du vill ta bort kategorin " + selectedCategoryWithChanges.name + "?")) {
                                                       props.swapState(core.handleRequest, "removeCategory", selectedCategoryId);
                                                   }
                                               }}>delete_forever</i>
                                        </button>
                                    </div>}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
