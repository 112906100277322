import * as util from "./util";
import * as constants from "./constants";
import {pageIds} from "./paths";

export function createInitialState() {
    return {
        pageId: pageIds.ADD_ENTITY,
        user: {
            data: null,
            isAdmin: false,
        },
        fetchingUserAuth: true,

        usernameInput: "",
        passwordInput: "",
        newCategory: getDefaultNewCategory(),
        newEntity: getDefaultNewEntity(),
        feedbackEntityCreated: false,
        entitiesChanges: {},
        selectedEntityId: null,
        categoryEditor: getDefaultCategoryEditor(),
        createUserChanges: null,

        entityFilter: getDefaultEntityFilter(),

        signIn: createDefaultRequestObject(),
        signOut: createDefaultRequestObject(),
        createUser: createDefaultRequestObject(),
        entities: createDefaultRequestObject({responseData: {}}),
        addEntity: createDefaultRequestObject(),
        updateEntity: createDefaultRequestObject(),
        removeEntity: createDefaultRequestObject(),
        categories: createDefaultRequestObject({responseData: {}}),
        addCategory: createDefaultRequestObject(),
        updateCategory: createDefaultRequestObject(),
        removeCategory: createDefaultRequestObject(),
    };
}
export function getDefaultNewCategory() {
    return {
        parentId: "",
        name: "",
    }
}
export function getDefaultCategoryEditor() {
    return {
        selectedCategoryId: null,
        changes: {}
    }
}

export function getDefaultEntityFilter() {
    return {
        name: "",
        categoryId: null,
        withImage: true,
        withoutImage: true,
        withCroppedImage: true,
        withoutCroppedImage: true,
        includeSubCategories: true,
        showOnlyNonUnique: false
    }
}

function getDefaultNewEntity() {
    return {
        type: constants.TYPE_WORD,
        wordType: "noun",
        categoryId: "",
        commonLevel: 1,
        spellingLevel: 1,
        name: "",
        gender: "",
        definite: "",
        plural: "",
        text: "",
        [constants.QUESTIONS]: [],
        imageBlobURL: null
    };
}

function createDefaultRequestObject(overrides) {
    return {
        requested: false,
        requestData: null,
        fetching: false,
        responseData: null,
        responseError: null,
        ...overrides,
    };
}

const onReceive = {
    "signIn": function (state) {
        return {
            ...state,
            passwordInput: "",
        }
    },
    "createUser": function (state) {
        return {
            ...state,
            usernameInput: getCreateUserNameInput(state),
            passwordInput: "",
            createUserChanges: null,
        }
    },
    "addCategory": function (state, data) {
        return {
            ...resetNewCategoryData(state),
            categories: {
                ...state.categories,
                responseData: {
                    ...state.categories.responseData,
                    [data.id]: data,
                }
            },
        }
    },
    "addEntity": function (state, data) {
        return {
            ...resetNewEntityData(state),
            feedbackEntityCreated: true,
            entities: {
                ...state.entities,
                responseData: {
                    ...state.entities.responseData,
                    [data.id]: data,
                }
            }
        };
    },
    "updateEntity": function (state, data) {
        return {
            ...(resetEntityChanges(state, {entityId: data.id})),
            pageId: pageIds.LIST_ENTITIES,
            selectedEntityId: null,
            entities: {
                ...state.entities,
                responseData: {
                    ...state.entities.responseData,
                    [data.id]: data,
                }
            }
        }
    },
    "updateCategory": function (state, data) {
        return {
            ...(resetCategoryChanges(state, {categoryId: data.id})),
            categories: {
                ...state.categories,
                responseData: {
                    ...state.categories.responseData,
                    [data.id]: data,
                }
            },
        }
    },
    "removeEntity": function (state, entityId) {
        delete state.entities.responseData[entityId];
        state.selectedEntityId = null;
        state.pageId = pageIds.LIST_ENTITIES;
        return resetEntityChanges(state, {entityId: entityId});
    },
    "removeCategory": function (state, categoryId) {
        delete state.categories.responseData[categoryId];
        state.categoryEditor.selectedCategoryId = null;
        return resetCategoryChanges(state, {categoryId: categoryId});
    }
};

export function isRequested(state, id) {
    return state[id] && state[id].requested;
}

export function getRequestData(state, id) {
    return state[id] && state[id].requestData;
}

export function isFetching(state, id) {
    return state[id] && state[id].fetching;
}

export function getResponseError(state, id) {
    return state[id] && state[id].responseError;
}

export function handleRequest(state, id, data) {
    return {
        ...state,
        [id]: {
            ...state[id],
            requested: true,
            requestData: data,
        },
    };
}

export function handleFetch(state, id) {
    return {
        ...state,
        [id]: {
            ...state[id],
            requested: false,
            fetching: true,
        },
    };
}

export function handleReceive(state, id, data) {
    return {
        ...(onReceive[id] ? onReceive[id](state, data) : state),
        [id]: {
            ...state[id],
            fetching: false,
            responseData: data,
            responseError: null,
        },
    };
}

export function handleError(state, id, data) {
    return {
        ...state,
        [id]: {
            ...state[id],
            fetching: false,
            responseError: data,
        },
    };
}

export function getPageId(state) {
    return state.pageId;
}

export function onPageIdChange(state, {pageId, pathParameters} = {}, {keepPageState = false} = {}) {
    state.selectedEntityId = pathParameters[0];
    state.pageId = pageId;
    if (!keepPageState) {
        if (pageId === pageIds.EDIT_ENTITY) {
            state.entitiesChanges = {};
        }
        if (pageId === pageIds.LIST_ENTITIES) {
            state.entityFilter = getDefaultEntityFilter();
        }
        if (pageId === pageIds.ADD_ENTITY) {
            state.newEntity = getDefaultNewEntity();
        }
        if (pageId === pageIds.EDIT_CATEGORIES) {
            state.categoryEditor = getDefaultCategoryEditor();
        }
        if (pageId === pageIds.ADD_CATEGORY) {
            state.newCategory = getDefaultNewCategory();
        }
    }
    return state;
}

export function isFetchingUserAuth(state) {
    return state.fetchingUserAuth;
}

export function isUserSignedIn(state) {
    return !!state.user.data;
}

export function isUserAdmin(state) {
    return !!state.user.isAdmin;
}

export function isEditingCreateUser(state) {
    return !!state.createUserChanges;
}

export function getUserData(state) {
    return state.user.data;
}

export function getUsernameInput(state) {
    return state.usernameInput;
}

export function getPasswordInput(state) {
    return state.passwordInput;
}

export function getEntityFilter(state) {
    return state.entityFilter;
}

export function getEntityWithChanges(state, entityId) {
    return {
        ...getEntityById(state, entityId),
        ...(state.entitiesChanges[entityId] || {}),
    };
}

export function getCreateUserNameInput(state) {
    return state.createUserChanges && state.createUserChanges.name;
}

export function getCreateUserPasswordInput(state) {
    return state.createUserChanges && state.createUserChanges.password;
}

export function getCategoryById(state, id) {
    return getCategoriesAsMap(state)[id];
}

export function getCategoriesAsMap(state) {
    return state.categories.responseData;
}

export function getCategoriesAsList(state, {sort = false} = {}) {
    let categories = Object.values(getCategoriesAsMap(state));
    if (sort) {
        categories = categories.sort((a, b) => compareCategories(state, a.id, b.id));
    }
    return categories;
}

export function getCategoriesByParentId(state, parentId) {
    return Object.values(state.categories.responseData)
        .filter(function (j) {
            return (!parentId && !j.parentId) || j.parentId === parentId;
        });
}

export function getSubCategoriesAsList(state, categoryId) {
    return Object.values(state.categories.responseData)
        .filter(function (j) {
            return !categoryId || j.parentId === categoryId;
        });
}

export function getEntitiesAsMap(state) {
    return state.entities.responseData;
}

export function getEntitiesAsList(state) {
    return [...Object.values(state.entities.responseData)];
}

function getLowerCaseEntityName(entity) {
    return (getEntityDisplayValue(entity) || "").toLowerCase().trim();
}

export function getEntityOccuranceMap(state) {
    let m = {};
    getEntitiesAsList(state).forEach(function (entity) {
        let name = getLowerCaseEntityName(entity);
        m[name] = (m[name] || 0) + 1;
    });
    return m;
}

export function getEntityDisplayValue(entity) {
    if (entity[constants.TYPE] === constants.TYPE_WORD) {
        return entity[constants.NAME];
    } else if (entity[constants.TYPE] === constants.TYPE_TEXT) {
        return entity[constants.TITLE];
    } else {
        return entity[constants.TEXT];
    }
}

export function getEntityTypeName(entity, definite) {
    if (entity[constants.TYPE] === constants.TYPE_WORD) {
        return definite ? "ordet" : "ord";
    } else if (entity[constants.TYPE] === constants.TYPE_TEXT) {
        return definite ? "texten" : "text";
    } else {
        return definite ? "meningen" : "mening";
    }
}

export function getFilteredAndSortedEntities(state) {
    let occurences = getEntityOccuranceMap(state);
    return getEntitiesAsList(state)
        .filter(function (entity) {
            if (state.entityFilter.name && !getEntityDisplayValue(entity).toUpperCase().startsWith(state.entityFilter.name.toUpperCase())) {
                return false;
            }
            if (state.entityFilter[constants.TYPE] && state.entityFilter[constants.TYPE] !== entity[constants.TYPE]) {
                return false;
            }
            if (state.entityFilter[constants.TYPE] === constants.TYPE_WORD && state.entityFilter[constants.WORD_TYPE] && state.entityFilter[constants.WORD_TYPE] !== entity[constants.WORD_TYPE]) {
                return false;
            }
            if (state.entityFilter.categoryId) {
                if (state.entityFilter.categoryId === constants.ALL_CATEGORIES) {
                    if (util.isNone(entity.categoryId)) {
                        return false;
                    }
                } else if (state.entityFilter.categoryId === constants.MISSING_CATEGORY) {
                    if (!util.isNone(entity.categoryId)) {
                        return false;
                    }
                } else {
                    if (!isAncestorCategory(state, state.entityFilter.categoryId, entity.categoryId)) {
                        return false;
                    }
                    if (!state.entityFilter.includeSubCategories && state.entityFilter.categoryId !== entity.categoryId) {
                        return false;
                    }
                }
            }
            if (util.isNone(entity.categoryId) && state.entityFilter.categoryId && state.entityFilter.categoryId !== constants.MISSING_CATEGORY) {
                return false;
            }
            if (!util.isNone(entity.categoryId) && state.entityFilter.categoryId === constants.MISSING_CATEGORY) {
                return false;
            }
            if (util.isNone(entity.imageURL) && !state.entityFilter.withoutImage) {
                return false;
            }
            if (!util.isNone(entity.imageURL) && !state.entityFilter.withImage) {
                return false;
            }
            if (util.isNone(entity[constants.IMAGE_BACKGROUND_MATCHES]) && !state.entityFilter.withoutCroppedImage) {
                return false;
            }
            if (!util.isNone(entity[constants.IMAGE_BACKGROUND_MATCHES]) && !state.entityFilter.withCroppedImage) {
                return false;
            }
            if (state.entityFilter.showOnlyNonUnique && occurences[getLowerCaseEntityName(entity)] <= 1) {
                return false;
            }
            return true;
        }).sort(function (entity1, entity2) {
            return util.compareStrings(getEntityDisplayValue(entity1), getEntityDisplayValue(entity2));
        });
}

export function getEntitiesByCategoryId(state, categoryId) {
    return getEntitiesAsList(state).filter(function (entity) {
        return entity.categoryId === categoryId;
    });
}

export function getNewCategory(state) {
    return state.newCategory;
}

export function getNewEntity(state) {
    return state.newEntity;
}

export function isNewEntityDuplicate(state) {
    return getEntityOccuranceMap(state)[getLowerCaseEntityName(state.newEntity)] > 1;
}

export function editEntityInputChange(state, {type, entityId, value}) {
    if (!state.entitiesChanges[entityId]) {
        state.entitiesChanges[entityId] = {};
    }
    state.entitiesChanges[entityId][type] = value;

    return state;
}

export function categoryEditorChange(state, {type, id, value}) {
    if (!state.categoryEditor.changes[id]) {
        state.categoryEditor.changes[id] = {};
    }
    state.categoryEditor.changes[id][type] = value;

    return state;
}

export function newCategoryInputChange(state, {type, value}) {
    state.newCategory[type] = value;
    return state;
}

export function newEntityInputChange(state, {type, value}) {
    state.newEntity[type] = value;
    state.feedbackEntityCreated = false;
    return state;
}

export function entityFilterChange(state, {type, value}) {
    state.entityFilter[type] = value;
    if (state.entityFilter[constants.WORD_TYPE] !== constants.WORD_TYPE_NOUN) {
        state.entityFilter[constants.CATEGORY_ID] = null;
    }
    return state;
}

export function getEntityById(state, entityId) {
    return getEntitiesAsMap(state)[entityId];
}

export function resetNewCategoryData(state) {
    return {
        ...state,
        newCategory: {
            parentId: "",
            name: "",
        }
    };
}

export function resetNewEntityData(state) {
    return {
        ...state,
        newEntity: getDefaultNewEntity()
    };
}

export function createUserEditChange(state, values) {
    if (!values) {
        return {
            ...state,
            createUser: {
                ...state.createUser,
                responseError: null,
            },
            createUserChanges: null,
        }
    } else {
        return {
            ...state,
            createUserChanges: {
                ...state.createUserChanges,
                ...values,
            },
        };
    }
}

export function onUsernameInputChange(state, value) {
    return {
        ...state,
        usernameInput: value,
    }
}

export function onPasswordInputChange(state, value) {
    return {
        ...state,
        passwordInput: value,
    }
}

export function userAuthChanged(state, userData, isAdmin) {
    if (!state.user.data && userData) {
        state = handleRequest(state, "entities");
        state = handleRequest(state, "categories");
    }
    return {
        ...state,
        user: {
            data: userData,
            isAdmin: isAdmin,
        },
        fetchingUserAuth: false
    }
}

export function anyCategoryChanges(state) {
    const oldCategory = getCategoryById(state, getSelectedCategoryEditorId(state)),
        newCategory = getCategoryWithChanges(state, getSelectedCategoryEditorId(state));
    return !util.objectEquals(oldCategory, newCategory);
}

export function anyEntityChanges(state, entityId) {
    const oldEntity = getEntityById(state, entityId), newEntity = getEntityWithChanges(state, entityId);
    return !util.objectEquals(oldEntity, newEntity);
}

export function resetEntityChanges(state, {entityId}) {
    delete state.entitiesChanges[entityId];
    return state;
}

export function resetCategoryChanges(state, {categoryId}) {
    delete state.categoryEditor.changes[categoryId];
    return state;
}

export function getEntityChanges(state, entityId) {
    return state.entitiesChanges[entityId];
}

export function getEntityChangesToUpdate(state) {
    return getEntityChanges(state, getRequestData(state, "updateEntity"));
}

export function isFetchingUpdateOrRemoveEntity(state, entityId) {
    if (entityId) {
        return (isFetching(state, "updateEntity") && (getRequestData(state, "updateEntity") === entityId)) || (isFetching(state, "removeEntity") && (getRequestData(state, "removeEntity") === entityId));
    }
    return isFetching(state, "updateEntity") || isFetching(state, "removeEntity");
}

export function isFetchingUpdateOrRemoveCategory(state, categoryId) {
    if (categoryId) {
        return (isFetching(state, "updateCategory") && (getRequestData(state, "updateCategory") === categoryId)) || (isFetching(state, "removeCategory") && (getRequestData(state, "removeCategory") === categoryId));
    }
    return isFetching(state, "updateCategory") || isFetching(state, "removeCategory");
}

export function getCategoryDepth(state, categoryId) {
    let depth = 0;
    while (getCategoryById(state, categoryId).parentId) {
        categoryId = getCategoryById(state, categoryId).parentId;
        depth++;
    }
    return depth;
}

export function compareCategories(state, categoryId1, categoryId2) {
    const initialDepth1 = getCategoryDepth(state, categoryId1), initialDepth2 = getCategoryDepth(state, categoryId2);
    while (getCategoryDepth(state, categoryId1) > getCategoryDepth(state, categoryId2)) {
        categoryId1 = getCategoryById(state, categoryId1).parentId;
    }
    while (getCategoryDepth(state, categoryId2) > getCategoryDepth(state, categoryId1)) {
        categoryId2 = getCategoryById(state, categoryId2).parentId;
    }
    while (getCategoryById(state, categoryId1).parentId && getCategoryById(state, categoryId2).parentId && getCategoryById(state, categoryId1).parentId !== getCategoryById(state, categoryId2).parentId) {
        categoryId1 = getCategoryById(state, categoryId1).parentId;
        categoryId2 = getCategoryById(state, categoryId2).parentId;
    }
    if (categoryId1 === categoryId2) {
        return initialDepth1 - initialDepth2;
    } else {
        return util.compareStrings(getCategoryById(state, categoryId1).name, getCategoryById(state, categoryId2).name);
    }
}

export function isAncestorCategory(state, category1, category2) {
    while (category2) {
        if (category2 === category1) {
            return true;
        }
        category2 = getCategoryById(state, category2).parentId;
    }
    return false;
}

export function isCategoryEditorCategorySelected(state, categoryId) {
    return getSelectedCategoryEditorId(state) === categoryId;
}

export function categoryEditorCategorySelected(state, {id}) {
    state.categoryEditor.selectedCategoryId = id;
    return state;
}

export function getSelectedCategoryEditorId(state) {
    return state.categoryEditor.selectedCategoryId;
}

export function getSelectedEntityId(state) {
    return state.selectedEntityId;
}

export function getCategoryWithChanges(state, categoryId) {
    return {
        ...getCategoryById(state, categoryId),
        ...(state.categoryEditor.changes[categoryId] || {}),
    };
}

function isValidEntity(entity) {
    if (entity[constants.TYPE] === constants.TYPE_WORD) {
        return entity[constants.NAME] && entity[constants.NAME].length;
    } else if (entity[constants.TYPE] === constants.TYPE_TEXT) {
        return entity[constants.TITLE] && entity[constants.TITLE].length;
    } else if (entity[constants.TYPE] === constants.TYPE_SENTENCE) {
        return entity[constants.TEXT] && entity[constants.TEXT].length;
    }
    return true;
}

function isValidCategory(state, category) {
    const {name, parentId} = category;
    return name && name.length && (util.isNone(parentId) || getCategoryById(state, parentId));
}

export function isValidNewEntity(state) {
    return isValidEntity(state.newEntity);
}

export function isUpdatedEntityValid(state, entityId) {
    return isValidEntity(getEntityWithChanges(state, entityId));
}

export function isValidNewCategory(state) {
    return isValidCategory(state, state.newCategory);
}

export function isValidUpdatedCategory(state) {
    return isValidCategory(state, getCategoryWithChanges(state, getSelectedCategoryEditorId(state)));
}

export function shouldShowEntityCreatedFeedback(state) {
    return state.feedbackEntityCreated;
}
