import React from "react";
import * as core from "../core";
import Select from "./Select";

export default function CategorySelect({state, onChange, value, disabled, label, extraOptions = [{value: "", label: "Ingen"}]}) {
    let options = extraOptions.concat(
        core.getCategoriesAsList(state, {sort: true})
            .map(function (category) {
                const labelPrefix = value !== category.id ? "--".repeat(core.getCategoryDepth(state, category.id)) : "";
                return {
                    value: category.id,
                    label: labelPrefix + category.name
                };
            })
    );
    return <Select disabled={disabled}
                   label={label}
                   value={value}
                   options={options}
                   onChange={onChange}/>;
}
