import React from "react";
import classNames from "classnames";
import _ from "understreck";
import * as core from "../core";
import {getUrl, pageIds, pageNames} from "../paths";
import './SideBar.css'

const menu = [{
    pageIds: [pageIds.LIST_ENTITIES, pageIds.EDIT_ENTITY],
    text: pageNames[pageIds.LIST_ENTITIES]
}, {
    pageIds: [pageIds.ADD_ENTITY],
    text: pageNames[pageIds.ADD_ENTITY]
}, {
    pageIds: [pageIds.EDIT_CATEGORIES],
    text: pageNames[pageIds.EDIT_CATEGORIES]
}, {
    pageIds: [pageIds.ADD_CATEGORY],
    text: pageNames[pageIds.ADD_CATEGORY]
}];

export default function SideBar(props) {
    const state = props.state;
    return (
        <div className={classNames("sideBar", "custom-sideBar")}>
            {menu.map(function ({pageIds, text}, index) {
                return <a key={index} href={getUrl(pageIds[0])}
                          className={classNames("menuItem", "pl3", "py2", _.includes(pageIds, core.getPageId(state)) ? "menuActive" : null)}
                          style={{fontWeight: "500", cursor: "pointer", fontSize: "20px"}}
                          onClick={function (e) {
                              e.preventDefault();
                              props.triggerEvent("navigate", {url: getUrl(pageIds[0]), test: "test"});
                          }}>{text}</a>
            })}
        </div>
    );
}
