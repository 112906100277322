import lodash from "lodash";
import AddCategory from "./pages/AddCategory";
import EditCategories from "./pages/EditCategories";
import AddEntity from "./pages/AddEntity";
import EntityList from "./pages/EntityList";
import EditEntity from "./pages/EditEntity";

const pathParamPattern = "([^/]+)";

export const pageIds = {
    ADD_CATEGORY: "ADD_CATEGORY",
    EDIT_CATEGORIES: "EDIT_CATEGORIES",
    ADD_ENTITY: "ADD_ENTITY",
    LIST_ENTITIES: "LIST_ENTITIES",
    EDIT_ENTITY: "EDIT_ENTITY"
};

export const pageNames = {
    [pageIds.ADD_CATEGORY]: "Skapa kategori",
    [pageIds.EDIT_CATEGORIES]: "Kategorier",
    [pageIds.ADD_ENTITY]: "Skapa objekt",
    [pageIds.LIST_ENTITIES]: "Komponenter",
    [pageIds.EDIT_ENTITY]: "Ändra objekt",
};

export const pages = {
    [pageIds.ADD_CATEGORY]: {
        urlPattern: "/add-category",
        component: AddCategory
    },
    [pageIds.EDIT_CATEGORIES]: {
        urlPattern: "/edit-categories",
        component: EditCategories
    },
    [pageIds.ADD_ENTITY]: {
        urlPattern: "/add-entity",
        component: AddEntity
    },
    [pageIds.LIST_ENTITIES]: {
        urlPattern: "/list-entities",
        component: EntityList
    },
    [pageIds.EDIT_ENTITY]: {
        urlPattern: `/edit-entity/${pathParamPattern}`,
        component: EditEntity
    }
};

export function getPageDataFromUrl(url) {
    return lodash.find(
        lodash.map(pages, function ({urlPattern}, pageId) {
            let match = url.match(urlPattern);
            if (match) {
                return {
                    pageId,
                    pathParameters: match.slice(1)
                };
            }
        })
    );
}

export function getUrl(pageId, {pathParameters = []} = {}) {
    let pageData = pages[pageId];
    let url = pageData && pageData.urlPattern;
    if (url) {
        return lodash.reduce(pathParameters, function (modifiedUrl, pathParam) {
            return modifiedUrl.replace(pathParamPattern, pathParam);
        }, url);
    }
}

export function getPageComponent(pageId) {
    let pageData = pages[pageId];
    return pageData && pageData.component;
}
