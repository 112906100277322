/* eslint-disable no-use-before-define */
import {initializeApp} from "firebase/app";
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, updateDoc} from 'firebase/firestore'
import {
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth"
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import config from "./config";
import * as constants from "./constants";
import * as core from "./core";
import understreck from "understreck"

const {firebase: firebaseConfig} = config;
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage()

function formatDoc(doc) {
    const data = {
        id: doc.id,
        ...doc.data(),
    };
    return Promise.resolve(data)
        .then(data => {
            const {[constants.IMAGE_URL]: image} = data;
            if (image) {
                return getDownloadURL(ref(storage, image))
                    .then(fullImageURL => ({
                        ...data,
                        [constants.DOWNLOAD_IMAGE_URL]: fullImageURL,
                    }))
            } else {
                return data;
            }
        }).then(data => {
            const {[constants.CROPPED_IMAGE_URL]: image} = data;
            if (image) {
                return getDownloadURL(ref(storage, image))
                    .then(fullImageURL => ({
                        ...data,
                        [constants.DOWNLOAD_CROPPED_IMAGE_URL]: fullImageURL,
                    }));
            } else {
                return data;
            }
        });
}

function addChangedTimestamp(data) {
    return {
        ...data,
        changed: Date.now(),
    };
}

async function uploadImage(imageURL) {
    const storageRef = ref(storage, `images/${understreck.uniqueId()}`)
    const r = await fetch(imageURL);
    const blob = await r.blob();
    const d = await uploadBytes(storageRef, blob);
    return d.ref.fullPath;
}

export function onAuthStateChangedEvent(callback) {
    return onAuthStateChanged(auth, (user) => {
        if (user) {
            user.getIdTokenResult()
                .then((idTokenResult) => ({
                    user: user,
                    isAdmin: idTokenResult && idTokenResult.claims && idTokenResult.claims.admin,
                }))
                .then(callback);
        } else {
            callback({user: user, isAdmin: false});
        }
    });
}

export function signIn(username, password) {
    return signInWithEmailAndPassword(auth, username, password);
}

export function userSignOut() {
    return signOut(auth);
}

export function createUser(username, password) {
    return createUserWithEmailAndPassword(auth, username, password);
}

function formatEntityForUpload(entity) {
    const uploadedObject = {};
    constants.getEntityFields(entity).forEach(field => {
        if (entity[field] && (typeof entity[field] !== "string" || entity[field].length)) {
            uploadedObject[field] = entity[field];
        }
    });
    return addChangedTimestamp(uploadedObject);
}

function formatCategoryForUpload(category) {
    const uploadedObject = {};
    constants.CATEGORY_FIELDS.forEach(field => {
        if (category[field] && (typeof category[field] !== "string" || category[field].length)) {
            uploadedObject[field] = category[field];
        }
    });
    return addChangedTimestamp(uploadedObject);
}

export async function uploadCategory(state) {
    const docRef = await addDoc(collection(db, "categories"), formatCategoryForUpload(core.getNewCategory(state)));
    const doc = await getDoc(docRef);
    return formatDoc(doc);
}

export async function uploadEntity(state) {
    const entity = core.getNewEntity(state);
    var promise;
    if (entity.imageBlobURL) {
        promise = uploadImage(entity.imageBlobURL);
    } else {
        promise = Promise.resolve();
    }

    const imageURL_1 = await promise;
    const docRef = await addDoc(collection(db, "entities"), formatEntityForUpload({
        ...entity,
        imageURL: imageURL_1,
    }));
    const doc = await getDoc(docRef);
    return formatDoc(doc);
}

export async function updateEntity(state) {
    const entityId = core.getRequestData(state, "updateEntity");

    var promise;
    if (core.getEntityChangesToUpdate(state).imageBlobURL) {
        promise = uploadImage(core.getEntityChangesToUpdate(state).imageBlobURL);
    } else {
        promise = Promise.resolve(core.getEntityWithChanges(state, entityId).imageURL);
    }

    const imageURL_1 = await promise;
    var docRef = doc(db, "entities", entityId);
    await updateDoc(docRef, formatEntityForUpload({
            ...core.getEntityWithChanges(state, entityId),
            imageURL: imageURL_1,
            [constants.CROPPED_IMAGE_URL]: null,
            [constants.IMAGE_BACKGROUND_MATCHES]: undefined
        })
    );
    const docResponse = await getDoc(docRef);
    return formatDoc(docResponse);
}

export async function removeEntity(state) {
    const entityId = core.getRequestData(state, "removeEntity");
    await deleteDoc(doc(db, "entities", entityId));
    return entityId;
}

export async function updateCategory(state) {
    const categoryId = core.getRequestData(state, "updateCategory");

    var docRef = doc(db, "categories", categoryId)
    await updateDoc(docRef, formatCategoryForUpload(core.getCategoryWithChanges(state, categoryId)));
    const doc = await getDoc(docRef);
    return formatDoc(doc);
}

export async function removeCategory(state) {
    const categoryId = core.getRequestData(state, "removeCategory");
    await deleteDoc(doc(db, "categories", categoryId));
    return categoryId;
}

export async function fetchCategories() {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const formattedEntities = await Promise.all(querySnapshot.docs.map(formatDoc));
    const finalEntities = {};
    formattedEntities.forEach((data) => {
        finalEntities[data.id] = data;
    });
    return finalEntities;
}

export async function fetchEntities() {
    const querySnapshot = await getDocs(collection(db, "entities"));
    const formattedEntities = await Promise.all(querySnapshot.docs.map(formatDoc));
    const finalEntities = {};
    formattedEntities.forEach((data) => {
        finalEntities[data.id] = data;
    });
    return finalEntities;
}
