import Events from "../events/events";
import _ from "understreck";

export default function ({data: initialData, immutable}) {
    var model = _.extend({}, Events);

    if (!initialData) {
        throw new Error("It is a rookie mistake not to provide a map. Keep on learning good stuff :).");
    }

    var data = initialData;

    function deref() {
        return data;
    }

    function swapMutable(f) {
        var args = Array.prototype.slice.call(arguments, 1);
        var newData = f.apply(null, [data].concat(args));
        if (!newData) { throw new Error("It is a rookie mistake not to return a map. Keep on learning good stuff :)."); }
        data = newData;
        model.trigger("change", data);
        return data;
    }

    function swapImmutable(f) {
        var args = Array.prototype.slice.call(arguments, 1);
        var newData = f.apply(null, [data].concat(args));
        if (!newData) { throw new Error("It is a rookie mistake not to return a map. Keep on learning good stuff :)."); }
        if (newData !== data) {
            data = newData;
            model.trigger("change", data);
        }
        return data;
    }

    model.deref = deref;
    model.swap = immutable ? swapImmutable : swapMutable;

    return model;
}
