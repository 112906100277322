import App from "./App";
import "./main.scss";
import "../node_modules/materialize-css/dist/css/materialize.css";
import "./index.css";
import config from "./config";

let initialState, onStateChange;
if (config.hotReload) {
  initialState = sessionStorage.getItem("dev_state");
  if (initialState) {
    initialState = JSON.parse(initialState);
  }
  onStateChange = state => sessionStorage.setItem("dev_state", JSON.stringify(state));
}
let system = {
    window: window
};
const app = App({ system, initialState, onStateChange });
app.start();
