import React from "react";
import _ from "understreck";

export default class Input extends React.Component {
    uniqueId = _.uniqueId();

    render() {
        const {onChange, value, disabled, label} = this.props;

        return <div>
            <label htmlFor={this.uniqueId}>
                {label}
            </label>
            <input disabled={disabled}
                   id={this.uniqueId}
                   type="text"
                   value={value}
                   onChange={function ({target: {value}}) {
                       onChange(value);
                   }}/>
        </div>;
    }
}
