import React from "react";
import _ from "understreck";
import classNames from "classnames";
import UploadModal from "./UploadModal";

function RemoveIcon() {
    return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.9697 36L35.0303 4M36 35.0303L4 4.9697" stroke="#C81111" strokeWidth="8" strokeLinecap="round"/>
    </svg>;
}

export default class ImageInput extends React.Component {
  uniqueId = _.uniqueId();
	state = {
		showCropModal: false
	}

  render() {
    const {onBlobUrlChange, blobUrl, imageUrl, disabled} = this.props;
    let hasImage = blobUrl || imageUrl;

		const toggleCropModal = () => {
			this.setState({ showCropModal: !this.state.showCropModal })
		}

    return (
			<div 
				style={{position: "relative", width: "300px", height: "300px", padding: "0px"}}
				onClick={() => {
					// if (hasImage) {
						toggleCropModal()
					// }
				}}
        className={classNames("card-panel", "teal", "lighten-4", !disabled && "hoverable", "imageEdit")}
			>
				<UploadModal
      	  show={this.state.showCropModal}
					toggleShow={toggleCropModal}
      	  className=''
      	  bodyText='Beskär bilden innan du laddar upp den. Placera motivet inom de röda linjerna för enhetligt resultat.'
      	  modalTitle='Beskär bilden'
      	  cancelText='Avbryt'
      	  okText='Beskär'
					onImageSet={onBlobUrlChange}
      	  onCancel={toggleCropModal}
      	/>
      	{hasImage &&
					<div style={{position: "absolute", top: "10px", right: "10px"}}
          	className={"removeImageButton"}
          	onClick={() => {
            	document.getElementById(this.uniqueId).value = null
            	onBlobUrlChange(null);
           	}}>
        		<RemoveIcon />
      		</div>}
      	<label htmlFor={this.uniqueId} style={{width: "100%", height: "100%"}}>
        {hasImage ?
          <img alt='preview' id={this.uniqueId} style={{cursor: !disabled && "pointer", width: "100%", height: "100%", objectFit: "contain"}}
          	src={blobUrl || imageUrl}/> :
          <div style={{fontSize: "20px", cursor: "pointer", width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center"}}>
            Välj bild
          </div>}
      	</label>
      	{/* <input
					disabled={disabled}
          style={{display: "none"}}
          type="file"
          id={this.uniqueId}
          onChange={function (e) {
            onBlobUrlChange(URL.createObjectURL(e.target.files[0]));
            }}
					/> */}
    	</div>
  		)};
}
