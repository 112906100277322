import React, { useState, useCallback, useRef, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, FormText } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './UploadModal.css'
import getCroppedImg from './UploadModalHelpers'
import html2canvas from 'html2canvas'
import classNames from "classnames";

const UploadModal = (props) => {
  const { className, modalTitle, bodyText, cancelText, okText, onCancel, show, toggleShow, onImageSet } = props
  const minZoom = 0.05
  const aspect = 1 / 1
  const [modal, setModal] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [imageBg, setImageBg] = useState('#ffffff')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  // const [croppedImage, setCroppedImage] = useState(null)
  // const [fileExt, setFileExt] = useState(null)
  // const [imageBlob, setImageBlob] = useState(null)

  const setBgColor = (ev) => {
    var x = 10;
    var y = 10;
  
    html2canvas(document.getElementsByClassName('CropCanvas')[0]).then((canvas) => {
       var ctx = canvas.getContext('2d');
       var p = ctx.getImageData(x, y, 1, 1).data; 
       var hex = "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
       setImageBg(hex)
    });
  }

  function rgbToHex(r, g, b) {
    if (r > 255 || g > 255 || b > 255)
        throw "Invalid color component";
    return ((r << 16) | (g << 8) | b).toString(16);
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      setImageSrc(imageDataUrl)
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  useEffect(() => {
    setModal(show)
  }, [show])

  const close = () => {
    setImageSrc(null)
    onCancel()
    setModal(!modal)
  }

  const handleSetCroppedImage = async () => {
    const image = await getCroppedImg(imageSrc, croppedAreaPixels, imageBg)
    onImageSet(image)
    setImageSrc(null)
    toggleShow()
  }

  useEffect(() => {
    if (imageSrc) {
      setBgColor()
    }
  }, [imageSrc])

  return (
      <Modal isOpen={show} className={classNames(className)} modalClassName={"upload-modal"} backdrop={false} style={{height: "fit-content"}}>
        <ModalHeader>
          {modalTitle}
        </ModalHeader>
        <ModalBody className='modal-body'>
          <div className="mb-3">
            <FormGroup>
              <Label for="exampleFile">
                {bodyText}
              </Label>
              <Input
                className="form-control shadow-none" id='formFile' type="file" accept='image/*' onChange={onFileChange}
              />
            </FormGroup>
          </div>
            {imageSrc &&
          <div className="crop-container">
            <div style={{
              pointerEvents: "none",
              position: "absolute",
              zIndex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <div style={{backgroundColor: "red", height: "25px", width: "1px"}}/>
            </div>
            <div style={{
              pointerEvents: "none",
              position: "absolute",
              zIndex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <div style={{backgroundColor: "red", height: "1px", width: "25px"}}/>
            </div>
            <Cropper
              id='cropper'
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              minZoom={minZoom}
              zoomWithScroll={true}
              aspect={aspect}
              onCropChange={setCrop}
              // cropSize={{width: "100%", height: "100%"}}
              objectFit={"cover"}
              restrictPosition={false}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={true}
              style={{
                containerStyle: {
                  backgroundColor: imageBg
                },
                cropAreaStyle: {
                  border: "1px solid #ff6761"
                }
              }}
              classes={ {mediaClassName: 'CropCanvas'} }
            />

          </div>
            }

          <div className="controls">
            <FormGroup>
              <div style={{display: "flex", alignItems: "center", marginBottom: "15px"}}>
                <input className={"color-input"}  type="color" id="head" name="head" value={imageBg} onChange={(e) => setImageBg(e.target.value)} />
                <label htmlFor="head">Välj bakgrundsfärg</label>
              </div>
              <Label for="zoom" style={{marginBottom: "0"}}>
                Zoom (Scrolla för snabb zoom)
              </Label>
              <Input
                id="zoom"
                name="zoom"
                type="range"
                value={zoom}
                onChange={(e) => setZoom(e.target.value)}
                min={minZoom}
                max={3}
                step='0.001'
              />
            </FormGroup>
          </div>
        </ModalBody>
        {/*<ModalFooter>*/}
        <div style={{display: "flex", justifyContent: "flex-end", gridGap: "20px"}}>
          <Button className='btn-danger' onClick={close} >{cancelText}</Button>
          <Button
            className='btn-primary'
            onClick={handleSetCroppedImage}
            disabled={!imageSrc}
          >
            {okText}
          </Button>{' '}
        </div>
        {/*</ModalFooter>*/}
      </Modal>
  )
}

export default UploadModal
