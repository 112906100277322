import * as core from "./core";
import * as services from "./services";

export default function handleSideEffects({state, swapState}) {
    if (core.isRequested(state, "signIn")) {
        services.signIn(core.getUsernameInput(state), core.getPasswordInput(state))
            .then(() => swapState(core.handleReceive, "signIn"))
            .catch((error) => swapState(core.handleError, "signIn", error.message));
        swapState(core.handleFetch, "signIn");

    } else if (core.isRequested(state, "signOut")) {
        services.userSignOut()
            .then(() => swapState(core.handleReceive, "signOut"))
            .catch((error) => swapState(core.handleError, "signOut", error.message));
        swapState(core.handleFetch, "signOut");

    } else if (core.isRequested(state, "createUser")) {
        services.createUser(core.getCreateUserNameInput(state), core.getCreateUserPasswordInput(state))
            .then(() => swapState(core.handleReceive, "createUser"))
            .catch((error) => swapState(core.handleError, "createUser", error.message));
        swapState(core.handleFetch, "createUser");

    } else if (core.isRequested(state, "categories")) {
        services.fetchCategories()
            .then((categories) => swapState(core.handleReceive, "categories", categories))
            .catch((error) => swapState(core.handleError, "categories", error.message));
        swapState(core.handleFetch, "categories");

    } else if (core.isRequested(state, "entities")) {
        services.fetchEntities()
            .then((entities) => swapState(core.handleReceive, "entities", entities))
            .catch((error) => swapState(core.handleError, "entities", error.message));
        swapState(core.handleFetch, "entities");

    } else if (core.isRequested(state, "addCategory")) {
        services.uploadCategory(state)
            .then((data) => swapState(core.handleReceive, "addCategory", data))
            .catch((error) => swapState(core.handleError, "addCategory", error.message));
        swapState(core.handleFetch, "addCategory");

    } else if (core.isRequested(state, "addEntity")) {
        services.uploadEntity(state)
            .then((data) => swapState(core.handleReceive, "addEntity", data))
            .catch((error) => swapState(core.handleError, "addEntity", error.message));
        swapState(core.handleFetch, "addEntity");

    } else if (core.isRequested(state, "updateEntity")) {
        services.updateEntity(state)
            .then((data) => swapState(core.handleReceive, "updateEntity", data))
            .catch((error) => swapState(core.handleError, "updateEntity", error.message));
        swapState(core.handleFetch, "updateEntity");

    } else if (core.isRequested(state, "removeEntity")) {
        services.removeEntity(state)
            .then((id) => swapState(core.handleReceive, "removeEntity", id))
            .catch((error) => swapState(core.handleError, "removeEntity", error.message));
        swapState(core.handleFetch, "removeEntity");

    } else if (core.isRequested(state, "removeCategory")) {
        services.removeCategory(state)
            .then((id) => swapState(core.handleReceive, "removeCategory", id))
            .catch((error) => swapState(core.handleError, "removeCategory", error.message));
        swapState(core.handleFetch, "removeCategory");

    } else if (core.isRequested(state, "updateCategory")) {
        services.updateCategory(state)
            .then((data) => swapState(core.handleReceive, "updateCategory", data))
            .catch((error) => swapState(core.handleError, "updateCategory", error.message));
        swapState(core.handleFetch, "updateCategory");

    }
}