import * as core from "../core";
import React from "react";
import Spinner from "../components/Spinner";
import {pageNames, pageIds} from "../paths";
import Input from "../components/Input";
import CategorySelect from "../components/CategorySelect";
import Grid from "../components/Grid";

export default function AddCategory(props) {
    const state = props.state;
    const newCategory = core.getNewCategory(state);
    const isFetching = core.isFetching(state, "addCategory");
    return (
        <div className="px4">
            <h2>{pageNames[pageIds.ADD_CATEGORY]}</h2>
            <Grid {...props}
                  elements={[{
                      id: "name",
                      element: <Input disabled={isFetching}
                                      label={"Namn"}
                                      value={newCategory.name}
                                      onChange={function (value) {
                                          props.swapState(core.newCategoryInputChange, {type: "name", value: value});
                                      }}/>
                  }, {
                      id: "parent",
                      element: <CategorySelect disabled={isFetching}
                                               state={state}
                                               label={"Förälder"}
                                               value={newCategory.parentId}
                                               onChange={function (value) {
                                                   props.swapState(core.newCategoryInputChange, {type: "parentId", value: value});
                                               }}/>
                  }]}/>
            <div className="col">
                {isFetching ?
                    <Spinner/> :
                    <button disabled={!core.isValidNewCategory(state)}
                            className="waves-effect waves-teal btn"
                            onClick={function () {
                                props.swapState(core.handleRequest, "addCategory");
                            }}>Skapa kategori</button>
                }
            </div>
        </div>
    );
}
