import React, {useState} from "react";
import * as constants from "../constants";
import * as util from "../util";
import classNames from "classnames";
import _ from "understreck";
import IconButton from "./IconButton";
import NavBox from "./NavBox";
import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import ImageInput from "./ImageInput";
import CategorySelect from "./CategorySelect";

export default function EntityEditForm(props) {
    const {state, entity, onUpdateEntity, disabled} = props;
    const entityFields = constants.getEntityFields(entity);

    const [questionIndex, setQuestionIndex] = useState(0);
    const uniqueId = _.uniqueId();

    function getInputElement(inputId) {
        if (entityFields.indexOf(inputId) === -1) {
            return null;
        }
        let inputData = constants.inputTypeMapping[inputId];
        if (inputData.type === constants.INPUT_TYPE_TEXT) {
            return <Input label={inputData.label}
                          disabled={disabled}
                          value={entity[inputId]}
                          onChange={function (value) {
                              onUpdateEntity({type: inputId, value: value});
                          }}/>;
        } else if (inputData.type === constants.INPUT_TYPE_SELECT) {
            return <Select disabled={disabled}
                           label={inputData.label}
                           value={entity[inputId]}
                           options={inputData.options}
                           onChange={function (value) {
                               onUpdateEntity({type: inputId, value: value});
                           }}/>;
        } else if (inputData.type === constants.INPUT_TYPE_TEXTAREA) {
            return <TextArea id={inputId + "-" + uniqueId}
                             label={inputData.label}
                             value={entity[inputId]}
                             disabled={disabled}
                             onChange={function (value) {
                                 onUpdateEntity({type: inputId, value: value});
                             }}/>;
        } else if (inputData.type === constants.INPUT_TYPE_CATEGORY) {
            return <CategorySelect disabled={disabled}
                                   state={state}
                                   label={inputData.label}
                                   value={entity[inputId] || ""}
                                   onChange={function (value) {
                                       onUpdateEntity({type: inputId, value: value});
                                   }}/>;
        } else if (inputData.type === constants.INPUT_TYPE_IMAGE) {
            return <ImageInput disabled={disabled}
                               blobUrl={entity.imageBlobURL}
                               imageUrl={entity.downloadImageURL}
                               onBlobUrlChange={function (value) {
                                   if (value || entity.imageBlobURL) {
                                       onUpdateEntity({type: "imageBlobURL", value: value});
                                   } else {
                                       onUpdateEntity({type: "imageURL", value: value});
                                       onUpdateEntity({type: "downloadImageURL", value: value});
                                       if (entity[constants.CROPPED_IMAGE_URL]) {
                                           onUpdateEntity({type: constants.CROPPED_IMAGE_URL, value: value});
                                       }
                                   }
                               }}/>;
        }  else if (inputData.type === constants.IMAGE_PREVIEW) {
            const backgroundMatches = entity[constants.IMAGE_BACKGROUND_MATCHES];
            return <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{fontWeight: "bold"}}>Preview</div>
                <div style={{
                    width: "300px",
                    height: "300px",
                    overflow: "hidden",
                    borderRadius: "15px",
                    backgroundColor: "white",
                    ...(backgroundMatches ? {
                        padding: "15px",
                        borderColor: "#ddd"
                    } : {})
                }}>
                    <img style={{height: "100%", width: "100%", aspectRatio: "1", objectFit: backgroundMatches ? "contain" : "cover"}}
                         src={entity[constants.DOWNLOAD_CROPPED_IMAGE_URL] || entity[constants.DOWNLOAD_IMAGE_URL]}
                         alt={"Cropped image not found"}/>
                </div>
            </div>
        } else if (inputData.type === constants.INPUT_TYPE_QUESTIONS) {
            let questions = entity[inputId] || [];
            let activeQuestion = questions[questionIndex];
            return (
                <div className="mt5">
                    <h5>{inputData.label}</h5>
                    <div className="dFlex flexWrap mb3">
                        {questions.map(function (question, index) {
                            return <NavBox key={index}
                                           active={index === questionIndex}
                                           onClick={function () {
                                               setQuestionIndex(index);
                                           }}>{index + 1}</NavBox>;
                        })}
                        <NavBox flat
                                onClick={function () {
                                    onUpdateEntity({type: inputId, value: questions.concat({questionText: "", alternatives: [""]})});
                                    setQuestionIndex(questions.length);
                                }}>
                            <i className="material-icons">add</i>
                        </NavBox>
                    </div>
                    {activeQuestion && <div>
                        <div className="dFlex alignItemsEnd">
                            <TextArea disabled={disabled}
                                      height={50}
                                      label="Fråga"
                                      value={activeQuestion.questionText}
                                      onChange={function (value) {
                                          questions[questionIndex].questionText = value;
                                          onUpdateEntity({type: inputId, value: questions});
                                      }}/>
                            <IconButton icon="delete"
                                        className="alignSelfCenter"
                                        color="red"
                                        onClick={function () {
                                            questions.splice(questionIndex, 1);
                                            setQuestionIndex(Math.min(questions.length - 1, questionIndex));
                                            onUpdateEntity({type: inputId, value: questions});
                                        }}/>
                        </div>
                        {activeQuestion.alternatives.map(function (alternative, index) {
                            return <div key={index} className="dFlex alignItemsEnd">
                                <div className="flexGrow1">
                                    <Input label={index === 0 ? "Rätt svar" : "Fel svar " + index}
                                           disabled={disabled}
                                           value={alternative}
                                           onChange={function (value) {
                                               activeQuestion.alternatives[index] = value;
                                               onUpdateEntity({type: inputId, value: questions});
                                           }}/>
                                </div>
                                {index !== 0 &&
                                <IconButton icon="delete"
                                            className="alignSelfCenter"
                                            color="red"
                                            onClick={function () {
                                                activeQuestion.alternatives.splice(index, 1);
                                                onUpdateEntity({type: inputId, value: questions});
                                            }}/>}
                            </div>;
                        })}
                        <IconButton icon="add" onClick={function () {
                            activeQuestion.alternatives.push("");
                            onUpdateEntity({type: inputId, value: questions});
                        }}/>
                    </div>}
                </div>
            );
        }
    }

    return (
        <div className={classNames("dFlex", "justifyContentBetween")}>
            <div className="col6 mr5">
                {getInputElement(constants.TYPE)}
                {getInputElement(constants.NAME)}
                {getInputElement(constants.PRONUNCIATION)}
                {getInputElement(constants.TITLE)}
                {getInputElement(constants.WORD_TYPE)}
                {getInputElement(constants.SENTENCE_TYPE)}
                {getInputElement(constants.CATEGORY_ID)}
                <div className={classNames("dFlex", "flexWrap")}>
                    {_.compact([constants.COMMON_LEVEL, constants.GRAMMAR_LEVEL, constants.SPELLING_LEVEL].map(getInputElement)).map(function (element, index) {
                        return <div key={index} className={classNames("col6", index % 2 ? "pl3" : "pr3")}>
                            {element}
                        </div>;
                    })}
                </div>
                {getInputElement(constants.GENDER)}
                {getInputElement(constants.DEFINITE)}
                {getInputElement(constants.PLURAL)}
                <div className="mt3">
                    {getInputElement(constants.TEXT)}
                </div>
            </div>
            <div className="col6">
                {getInputElement(constants.IMAGE_URL)}
                {/* Don't really know how this IMAGE_BACKGROUND_MATCHES worked / Mathias */}
                {/*{!util.isNone(entity[constants.IMAGE_BACKGROUND_MATCHES]) ? getInputElement(constants.CROPPED_IMAGE_URL) : null}*/}
                {entity[constants.DOWNLOAD_CROPPED_IMAGE_URL] || entity[constants.DOWNLOAD_IMAGE_URL] ? getInputElement(constants.CROPPED_IMAGE_URL) : null}
                {getInputElement(constants.QUESTIONS)}
            </div>
        </div>
    );
}
