import React from "react";
import _ from "understreck";

export default class Select extends React.Component {
    uniqueId = _.uniqueId();

    render() {
        const {onChange, value, options = [], disabled, label} = this.props;

        return <div>
            <label htmlFor={this.uniqueId}>
                {label}
            </label>
            <select disabled={disabled}
                    className="browser-default"
                    id={this.uniqueId}
                    value={value}
                    onChange={function ({target: {value}}) {
                        onChange(value);
                    }}>
                {options.map(function ({label, value}) {
                    return <option key={value} value={value}>{label}</option>;
                })}
            </select>
        </div>;
    }
}
