export function sortByKey(array, keyFunction) {
    return array.sort((a, b) => compareStrings(keyFunction(a), keyFunction(b)));
}

export function compareStrings(s1, s2) {
    if (s1) {
        if (s2) {
            return s1.localeCompare(s2, "sv", {"sensitivity": "base"});
        } else {
            return 1;
        }
    } else {
        if (s2) {
            return -1;
        } else {
            return 0;
        }
    }
}

export function onEnterKey(callback) {
    return function (event) {
        if (event.key === "Enter") {
            callback();
        }
    }
}

export function objectEquals(o1, o2) {
    return Object.keys(o1).concat(Object.keys(o2)).every(k => equals(o1[k], o2[k]));
}

export function equals(a, b) {
    return (isNone(a) && isNone(b))|| a === b;
}

export function isNone(a) {
    return a == null || a === "";
}
