import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import * as core from "../core";
import Spinner from "../components/Spinner";
import {pageNames, pageIds, getUrl} from "../paths";
import * as constants from "../constants";
import Link from "../components/Link";
import Select from "../components/Select";
import Input from "../components/Input";
import CategorySelect from "../components/CategorySelect";
import CheckBox from "../components/CheckBox";
import Grid from "../components/Grid";

const MAX_RESULTS_INCREASE = 50;

export default function EntityList(props) {
    const {state, parentRef} = props;
    const entityFilter = core.getEntityFilter(state);
    let [maxResults, setMaxResults] = useState(MAX_RESULTS_INCREASE);
    let availableEntities = core.getFilteredAndSortedEntities(state);
    let shownEntities = availableEntities.slice(0, maxResults);

    const updateFilter = useCallback(value => {
        props.swapState(core.entityFilterChange, value);
        if (maxResults !== MAX_RESULTS_INCREASE) {
            setMaxResults(MAX_RESULTS_INCREASE);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxResults, setMaxResults]);

    const handleScroll = useCallback(event => {
        let element = event.target;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 30) {
            if (maxResults < availableEntities.length) {
                setMaxResults(maxResults + MAX_RESULTS_INCREASE);
            }
        }
    }, [setMaxResults, maxResults, availableEntities.length]);

    useEffect(() => {
        if (parentRef.current) {
            let element = parentRef.current;
            element.addEventListener("scroll", handleScroll);
            return () => {
                element.removeEventListener("scroll", handleScroll);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentRef.current, handleScroll]);

    const isWord = entityFilter[constants.TYPE] === constants.TYPE_WORD;
    const isNoun = isWord && entityFilter[constants.WORD_TYPE] === constants.WORD_TYPE_NOUN;

    return (
        <div className="px4">
            <h2>{pageNames[pageIds.LIST_ENTITIES]}</h2>
            {core.isFetching(state, "entities") ?
                <Spinner/> :
                <div>
                    <Grid {...props}
                          elements={[{
                              id: "name",
                              element: <Input label={"Filtrera ord"}
                                              value={entityFilter.name}
                                              onChange={function (value) {
                                                  updateFilter({type: "name", value: value});
                                              }}/>
                          }]}/>
                    <Grid {...props}
                          elements={[{
                              id: constants.TYPE,
                              element: <Select label={constants.inputTypeMapping[constants.TYPE].label}
                                               value={entityFilter[constants.TYPE] || ""}
                                               options={[{
                                                   value: "",
                                                   label: ""
                                               }].concat(constants.inputTypeMapping[constants.TYPE].options)}
                                               onChange={function (value) {
                                                   updateFilter({type: constants.TYPE, value: value});
                                               }}/>
                          }, isWord && {
                              id: "wordType",
                              element: <Select label={constants.inputTypeMapping[constants.WORD_TYPE].label}
                                               value={entityFilter[constants.WORD_TYPE] || ""}
                                               options={[{
                                                   label: "Alla ordklasser",
                                                   value: ""
                                               }].concat(constants.inputTypeMapping[constants.WORD_TYPE].options)}
                                               onChange={function (value) {
                                                   updateFilter({type: constants.WORD_TYPE, value: value});
                                               }}/>
                          }]}/>
                    {isNoun && <Grid {...props}
                           elements={[{
                               id: "category",
                               element: <CategorySelect label={"Kategori"}
                                                        state={state}
                                                        value={entityFilter.categoryId || ""}
                                                        extraOptions={[{
                                                            value: "",
                                                            label: ""
                                                        }, {
                                                            value: constants.ALL_CATEGORIES,
                                                            label: "Alla katgorier"
                                                        }, {
                                                            value: constants.MISSING_CATEGORY,
                                                            label: "Saknar kategori"
                                                        }]}
                                                        onChange={function (value) {
                                                            updateFilter({type: "categoryId", value: value});
                                                        }}/>
                           }, {
                               id: "sub-categories",
                               element: <CheckBox label={"Inkludera underkategorier"}
                                                  value={core.getEntityFilter(state).includeSubCategories}
                                                  onChange={function (value) {
                                                      updateFilter({type: "includeSubCategories", value: value});
                                                  }}/>
                           }]}/>}
                    <Grid {...props}
                          elements={[{
                              id: "no-image",
                              element: <CheckBox label={"Visa ord utan bild"}
                                                 value={core.getEntityFilter(state).withoutImage}
                                                 onChange={function (value) {
                                                     updateFilter({type: "withoutImage", value: value});
                                                 }}/>
                          }, {
                              id: "image",
                              element: <CheckBox label={"Visa ord med bild"}
                                                 value={core.getEntityFilter(state).withImage}
                                                 onChange={function (value) {
                                                     updateFilter({type: "withImage", value: value});
                                                 }}/>
                          }]}/>
                    <Grid {...props}
                          elements={[{
                              id: "no-cropped-image",
                              element: <CheckBox label={"Visa icke-processade"}
                                                 value={core.getEntityFilter(state).withoutCroppedImage}
                                                 onChange={function (value) {
                                                     updateFilter({type: "withoutCroppedImage", value: value});
                                                 }}/>
                          }, {
                              id: "cropped-image",
                              element: <CheckBox label={"Visa processade"}
                                                 value={core.getEntityFilter(state).withCroppedImage}
                                                 onChange={function (value) {
                                                     updateFilter({type: "withCroppedImage", value: value});
                                                 }}/>
                          }]}/>
                    <Grid {...props}
                          elements={[{
                              id: "doubles",
                              element: <CheckBox label={"Visa endast dubbletter"}
                                                 value={core.getEntityFilter(state).showOnlyNonUnique}
                                                 onChange={function (value) {
                                                     updateFilter({type: "showOnlyNonUnique", value: value});
                                                 }}/>
                          }]}/>
                    <div className={classNames("mb3")}>
                        <span>Visar </span>
                        <span className={"bold"}>{availableEntities.length}</span>
                        <span> resultat</span>
                    </div>
                    <div className={"entityListResult"}>
                        {shownEntities.map((entity) => {
                            return (
                                <Link {...props}
                                      className="teal-text"
                                      key={entity.id}
                                      href={getUrl(pageIds.EDIT_ENTITY, {pathParameters: [entity.id]})}>
                                    <div>
                                        {entity.downloadImageURL ?
                                            <img src={entity.downloadImageURL} alt="" style={{width: "30px", height: "30px"}}/> :
                                            <i className="material-icons" style={{width: "30px"}}>text_fields</i>}
                                        <div className="title" style={{marginLeft: "10px"}}>
                                            {core.getEntityDisplayValue(entity)}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>}
        </div>
    );
}
