import React from "react";
import * as core from "../core";
import * as util from "../util";
import Spinner from "../components/Spinner";
import Input from "../components/Input";

export default function SignIn(props) {
    const state = props.state;
    const signInError = core.getResponseError(state, "signIn");
    const createUserError = core.getResponseError(state, "createUser");

    return (
        <div className="container valign-wrapper dFlex alignItemsCenter justifyContentCenter" style={{height: "100%"}}>
            <div className="dFlex flexColumn alignItemsCenter teal lighten-4 p5" style={{width: "400px"}}>
                {core.isEditingCreateUser(state) ?
                    <form onSubmit={function (e) {
                        e.preventDefault();
                        props.swapState(core.handleRequest, "createUser");
                    }}>
                        <React.Fragment>
                            <input type="text"
                                   value={core.getCreateUserNameInput(state)}
                                   placeholder="Email"
                                   onChange={function ({target: {value}}) {
                                       props.swapState(core.createUserEditChange, {name: value});
                                   }}/>
                            <input type="password"
                                   value={core.getCreateUserPasswordInput(state)}
                                   placeholder="Password"
                                   onChange={function ({target: {value}}) {
                                       props.swapState(core.createUserEditChange, {password: value});
                                   }}/>
                            {createUserError && <div style={{color: "red"}}>{createUserError}</div>}
                            {core.isFetching(state, "createUser") ?
                                <Spinner/> :
                                <div>
                                    <button className="waves-effect waves-teal btn mr2"
                                            type="submit">
                                        Create
                                    </button>
                                    <button className="waves-effect grey btn"
                                            onClick={function () {
                                                props.swapState(core.createUserEditChange, null);
                                            }}>Cancel</button>
                                </div>}
                        </React.Fragment>
                    </form> :
                    <React.Fragment>
                        <form className="dFlex flexColumn alignItemsCenter"
                              onSubmit={function (e) {
                                  e.preventDefault();
                                  props.swapState(core.handleRequest, "signIn");
                              }}>
                            <input type="text"
                                   value={core.getUsernameInput(state)}
                                   placeholder="Name"
                                   onChange={function ({target: {value}}) {
                                       props.swapState(core.onUsernameInputChange, value);
                                   }}/>
                            <input type="password"
                                   value={core.getPasswordInput(state)}
                                   placeholder="Password"
                                   onChange={function ({target: {value}}) {
                                       props.swapState(core.onPasswordInputChange, value);
                                   }}/>
                            {signInError && <div style={{color: "red"}}>{signInError}</div>}
                            {core.isFetching(state, "signIn") ?
                                <Spinner/> :
                                <button className="waves-effect waves-teal btn"
                                        type="submit">
                                    Login
                                </button>}
                        </form>
                        <button className="btn-flat"
                                onClick={function () {
                                    props.swapState(core.createUserEditChange, {});
                                }}>Create User</button>
                    </React.Fragment>
                }
            </div>
        </div>
    );
};
