import * as core from "../core";
import React, {useState} from "react";
import Spinner from "../components/Spinner";
import {pageNames, pageIds, getUrl} from "../paths";
import EntityEditForm from "../components/EntityEditForm";

export default function EditEntity(props) {
    const state = props.state;
    const entityId = core.getSelectedEntityId(state);
    const entity = core.getEntityWithChanges(state, entityId);
    const isFetching = core.isFetchingUpdateOrRemoveEntity(state, entityId);

    const [validated, setValidated] = useState(false);

    return (
        <div className="px4 py4 width900">
            <h2>{pageNames[pageIds.EDIT_ENTITY]}</h2>
            <EntityEditForm {...props}
                            state={state}
                            entity={entity}
                            onUpdateEntity={function({type, value}) {
                                props.swapState(core.editEntityInputChange, {type: type, entityId: entityId, value: value});
                            }}
                            disabled={isFetching}/>
            <div className="mt3">
                {isFetching ?
                    <Spinner/> :
                    <React.Fragment>
                        <button className="waves-effect waves-teal btn"
                                disabled={!core.anyEntityChanges(state, entityId)}
                                onClick={function () {
                                    if (core.isUpdatedEntityValid(state, entityId)) {
                                        props.swapState(core.handleRequest, "updateEntity", entityId);
                                    } else {
                                        setValidated(true)
                                    }
                                }}>Spara
                            <i className="material-icons right">save</i></button>
                        <button className="ml3 waves-effect grey btn"
                                disabled={!core.anyEntityChanges(state, entityId)}
                                onClick={function () {
                                    props.swapState(core.resetEntityChanges, {entityId: entityId});
                                }}>Reset
                            <i className="material-icons right">restart_alt</i>
                        </button>
                        <button className="ml3 waves-effect grey btn"
                                onClick={function () {
                                    props.triggerEvent("navigate", {url: getUrl(pageIds.LIST_ENTITIES), keepPageState: true})
                                }}>
                            Avbryt
                            <i className="material-icons right">close</i>
                        </button>
                        <button className="ml5 waves-effect waves-light red lighten-1 btn"
                                style={{float: "right"}}
                                onClick={function () {
                                    if (props.system.window.confirm("Är du säker på att du vill ta bort " + core.getEntityTypeName(entity, true) + " \"" + core.getEntityDisplayValue(entity)+ "\"?")) {
                                        props.swapState(core.handleRequest, "removeEntity", entityId);
                                    }
                                }}>
                            Ta bort {core.getEntityTypeName(entity)}
                            <i className="material-icons right">delete</i>
                        </button>
                    </React.Fragment>
                }

            </div>
            {validated && !core.isUpdatedEntityValid(state, entityId) && (
                    <div className="red-text lighten-2 mt2"> Objektet har dåliga värden. Kontrollera vad du skrivit in och försök igen</div>
            )}
        </div>
    );
}
